import { GLOBALS } from '@/constants'
import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'uniemensCollab',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'UNIMENS COLL',
    CODPERMESSO: 'RII_COLL',
}

function propsFn(route) {
    const params = {
        ...route.params,
        year: Number(route.params.year),
        month: Number(route.params.month),
        day: route.params.day ? 1 : Number(route.params.day),
        dittaId: Number(route.params.dittaId),
        dittaPadreId: Number(route.params.dittaPadreId),
        mod: route.params.mod ?? GLOBALS.DATA_MODE.MOD,
        id: Number(route.params.id),
    }

    if (route.name != SETTINGS.LISTA.NAME) {
        params.mod = route.params.mod ?? GLOBALS.DATA_MODE.MOD
        params.id = Number(route.params.id)
    }

    return params
}

export const SETTINGS = Object.freeze({
    LISTA: {
        TITLE: 'Elenco Collaboratori - Uniemens - Collaboratori - Gestione - Paghe',
        LABEL: 'Elenco Collaboratori Uniemens',
        PATH: 'uniemensCollab/:year/:month/:day/:dittaId/:dittaPadreId',
        NAME: 'uniemensElencoCollab',
        TAB: { ...tabPropsDefault },
    },
    DETTAGLIO: {
        TITLE: 'Dettaglio Collaboratore - Uniemens - Collaboratore - Gestione - Paghe',
        LABEL: 'Dettaglio Collaboratore',
        PATH: 'uniemensCollab/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod',
        NAME: 'uniemensCollabDettaglio',
        TAB: { ...tabPropsDefault },
    },
    GENERALE: {
        TITLE: '',
        LABEL: 'Generale',
        PATH: 'generaleCollab',
        NAME: 'uniemensCollabGenerale',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.LISTA.PATH,
            name: SETTINGS.LISTA.NAME,
            component: pagheViews.uniemens.collaboratore.liste.Elenco, // TODO: DA CAMBIARE CON IL COMPONENTE GIUSTO
            meta: {
                title: SETTINGS.LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    /* codVoceMenu: tabPropsDefault.CODVOCEMENU, */
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: propsFn,
            //props: true,
        },
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            props: propsFn,
            component: pagheViews.uniemens.collaboratore.dettaglio.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    label: SETTINGS.GENERALE.LABEL,
                    path: SETTINGS.GENERALE.PATH,
                    name: SETTINGS.GENERALE.NAME,
                    tab: SETTINGS.GENERALE.TAB,
                    component: pagheViews.uniemens.collaboratore.dettaglio.childs.Generale,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNC01',
                    },
                    orderWizard: 0,
                    props: propsFn,
                },
            ],
        },
    ],
}
