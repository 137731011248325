import aliquota from './aliquota'
import banche from './Banche'
import cassaEdile from './cassaEdile'
import causaliEvento from './causaliEvento'
import codiceDiario from './codice-diario'
import comune from './comune'
import contratto from './contratto'
import ente from './ente'
import minimaliMassimali from './minimaliMassimali'
import prospettoContabileCod from './prospettoContabileCod'
import prospettoContabileRiga from './prospettoContabileRiga'
import provincia from './provincia'
import retrConvenzionale from './retrConvenzionale'
import scadenze from './scadenze'
import sgravioFiscalizzazione from './sgravioFiscalizzazione'
import sindacato from './sindacato'
import varie from './varie'
import VCaaf from './VCaaf'
import vCodDes from './vCodDes'
import vCodiceUniemens from './vCodiceUniemens'
import vCodiceUnimare from './vCodiceUnimare'
import vContrattoCNEL from './vContrattoCNEL'
import vContrattoCO from './vContrattoCO'
import vContrattoInps from './VContrattoInps'
import vCostante from './vCostante'
import vCreditoImposta from './vCreditoImposta'
import vDataVersamentoF24 from './vDataVersamentoF24'
import vDatoFisso from './vDatoFisso'
import vDatoMese from './vDatoMese'
import vInailEsazione from './VInailEsazione'
import vInailInteressi from './VInailInteressi'
import VInailSconto from './VInailSconto'
import vInailSede from './vInailSede'
import vInailVoceTariffa from './vInailVoceTariffa'
import vIrpefDeduzione from './vIrpefDeduzione'
import vIrpefDetrAltroCarico from './vIrpefDetrAltroCarico'
import vIrpefDetrConiuge from './vIrpefDetrConiuge'
import vIrpefDetrLavDipte from './vIrpefDetrLavDipte'
import vIrpefDetrRpInfAnno from './vIrpefDetrRpInfAnno'
import vIrpefScaglImposta from './vIrpefScaglImposta'
import vIrpefTrattIntegr from './vIrpefTrattIntegr'
import VMaggiorazioneSpettante from './VMaggiorazioneSpettante'
import vMessaggi from './vMessaggi'
import voce from './voce'
import vProprieta from './vProprieta'
import vQualificaDipte from './vQualificaDipte'
import vQualificaInps from './vQualificaInps'
import vQualificaSviluppoAliq from './vQualificaSviluppoAliq'
import VSedeInps from './VSedeInps'
import vTipoRapporto from './vTipoRapporto'
import VTipoRetribuzione from './vTipoRetribuzione'
import vTributo from './vTributo'
export default {
    aliquota,
    banche,
    cassaEdile,
    causaliEvento,
    codiceDiario,
    contratto,
    comune,
    ente,
    minimaliMassimali,
    prospettoContabileCod,
    prospettoContabileRiga,
    provincia,
    retrConvenzionale,
    scadenze,
    sgravioFiscalizzazione,
    sindacato,
    varie,
    VCaaf,
    vCodDes,
    vCodiceUniemens,
    vCodiceUnimare,
    vContrattoCNEL,
    vContrattoCO,
    vContrattoInps,
    vCostante,
    vCreditoImposta,
    vDataVersamentoF24,
    vDatoFisso,
    vDatoMese,
    vInailEsazione,
    vInailInteressi,
    VInailSconto,
    vInailSede,
    vInailVoceTariffa,
    vIrpefDeduzione,
    vIrpefDetrAltroCarico,
    vIrpefDetrConiuge,
    vIrpefDetrLavDipte,
    vIrpefDetrRpInfAnno,
    vIrpefScaglImposta,
    vIrpefTrattIntegr,
    VMaggiorazioneSpettante,
    vMessaggi,
    voce,
    vProprieta,
    vQualificaDipte,
    vQualificaInps,
    vQualificaSviluppoAliq,
    VSedeInps,
    vTipoRapporto,
    VTipoRetribuzione,
    vTributo,
}
